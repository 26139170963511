export const countries = {
  cs: 'Czech Cestina',
  da: 'Danish',
  de: 'German',
  'de-BE': 'German (Belgium)',
  en: 'USA',
  'en-AU': 'English (Australia)',
  'en-BE': 'English (Belgium)',
  'en-CA': 'English (Canada)',
  'en-DK': 'English (Denmark)',
  'en-GB': 'English (UK)',
  'en-HK': 'English (Hong Kong SAR)',
  'en-ID': 'English (Indonesia)',
  'en-IE': 'English (Ireland)',
  'en-IN': 'English (India)',
  'en-MY': 'English (Malasia)',
  'en-NG': 'English (Nigeria)',
  'en-NO': 'English (Norway)',
  'en-NZ': 'English (New Zealand)',
  'en-PH': 'English (Philippines)',
  'en-SG': 'English (Singapore)',
  'en-ZA': 'English (South Africa)',
  es: 'Spanish',
  'es-CO': 'Spanish (Colombia)',
  'es-ES': 'Spanish (Spain)',
  'es-MX': 'Spanish (Mexico)',
  fi: 'Finnish',
  fr: 'France',
  'fr-BE': 'French (Belgium)',
  'fr-CA': 'French (Canada)',
  it: 'Italy',
  ja: 'Japan',
  ko: 'Korea',
  nb: 'Norwegian Bokmal',
  nl: 'Dutch',
  'nl-BE': 'Dutch (Belgium)',
  pl: 'Polish',
  pt: 'Portuguese',
  'pt-PT': 'Portuguese (Portugal)',
  'pt-BR': 'Portuguese (Brazil)',
  sv: 'Swedish',
  th: 'Thai',
  tr: 'Turkish',
  zh: 'Chinese',
  'zh-CN': 'Chinese (China)',
  'zh-HK': 'Chinese (Hong Kong)',
  'zh-TW': 'Chinese (Taiwan)',
};

export default [
  'cs',
  'da',
  'de',
  'de-BE',
  'en',
  'en-001',
  'en-150',
  'en-AU',
  'en-BE',
  'en-CA',
  'en-DK',
  'en-GB',
  'en-HK',
  'en-ID',
  'en-IE',
  'en-IN',
  'en-MY',
  'en-NG',
  'en-NO',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-ZA',
  'es',
  'es-419',
  'es-CO',
  'es-ES',
  'es-MX',
  'fi',
  'fr',
  'fr-BE',
  'fr-CA',
  'it',
  'ja',
  'ko',
  'nb',
  'nl',
  'nl-BE',
  'pl',
  'pt',
  'pt-BR',
  'pt-PT',
  'sv',
  'th',
  'tr',
  'zh',
  'zh-CN',
  'zh-HK',
  'zh-Hans',
  'zh-Hant',
  'zh-Hant-HK',
  'zh-TW',
];
